import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom"; // Change import
import ScrollToTop from "./ScrollToTop";

function Footer() {
  let date = new Date();

  // const quickLinks = [
  //   { label: 'Home', url: '/' },
  //   { label: 'Demo', url: '/demo' },
  //   { label: 'Featured', url: '/featured' },
  //   { label: 'Interocitor™', url: '/interocitor' },
  //   { label: 'Downloads', url: '/downloads' },
  //   { label: 'Knowledge Base', url: 'https://wiki.globalnation.tv' }, // External URL
  //   { label: 'Terms of Service', url: '/terms-of-service' },
  //   { label: 'Privacy Policy', url: '/privacy-policy' },
  // ];

  const [quickLinks,setQuickLinks]=useState([])
  const [email,setemail]=useState('')
  const [desc,setdesc]=useState('')
  const [copyright,setcopyright]=useState('')
  const [title,settitle]=useState('')

  useEffect(async()=>{
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      // const response = await fetch(`http://127.0.0.1:8000/api/all-pages-data?pageslug=${pathUrl}&pagestatus=${pageStatus}`);
      const response = await fetch(
        `${baseUrl}/api/footer-data`
      );
       const data = await response.json();
       console.log(data);
       console.log(typeof(data));
       setemail(data.footer_data[0].eMail)
       setdesc(data.footer_data[0].description)
       settitle(data.footer_data[0].title)
       setcopyright(data.footer_data[0].Copyright)
       console.log(JSON.stringify(data));
       console.log(data.footer_data[0].eMail);
       
       
       console.log(data?.footer_data[0]?.quick_links,'data');
       
      setQuickLinks(data?.footer_data[0]?.quick_links)
      // const data = await response.json();
      // console.log(data,'data');
    } catch (error) {
      
    }
   
  },[])

  const generateHtmlContent = () => {
    return quickLinks.map((link, index) => {
      const isExternal = link.link_url.startsWith('http') || link.link_url.startsWith('www');
      return (
        `<li key="${index}">
          ${isExternal 
            ? `<a href="${link.link_url}" target="_blank" rel="noopener noreferrer " class='footer-list'>${link.link_title}</a>` 
            : `<a href="${link.link_url}" class="footer-list nav-link">${link.link_title}</a>`
          }
        </li>`);
    }).join('');
  };

  const htmlContent = generateHtmlContent()
  console.log(htmlContent,'htmlContent');
  
  return (
    <Container fluid className="footer">
      <ScrollToTop />
      <Row>
        <Col md="8" className="footer-left-sec text-left">
          <h2 className="heading-title">
            <NavLink exact to="/" activeClassName="active"><div key={1} dangerouslySetInnerHTML={{ __html: title}} /></NavLink>
          </h2>
          <p>
           <div key={1} dangerouslySetInnerHTML={{ __html: desc}} />
          </p>
          <p>
            <a className="email" href="mailto:info@globalnation.tv">
              <div key={1} dangerouslySetInnerHTML={{ __html: email}} />
            </a>
          </p>
        </Col>
        <Col md="4" className="footer-right-sec">
          <div className="quick-links">
            <h2>Quick Links</h2>
            <ul dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="4" className="footer-copywright">
          <p>  
          <div key={1} dangerouslySetInnerHTML={{ __html: copyright}} />
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;







